.heroDiv{
 width: 100%;
 height: 70vh; 
 position: relative;
 overflow: hidden;
 margin-bottom: 40px;
}

.heroDiv img{
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  object-position: bottom;
}

.mainPage{
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  border-radius: 20px;
}

.productDiv{
  width: 100%;
  margin-top: 40px;
}

.verifypage{
  padding: 10px;
}

.prodDiv1{
  width: 300px;
  height: 400px;
  border-radius: 7px;
  padding: 20px;
  float: left;
  margin-right: 30px;
}

.prodDiv2{
  width: 100%;
  height: 200px;
  background-color: aliceblue;
  border-radius: 7px;
  padding: 20px;
  margin-bottom: 10px;
}



.productImage{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.prodDiv1 h6{
  margin: 0;
  padding: 0;
}

.prodDiv1 h4{
  margin: 0;
  padding: 0;
}

.prodDiv1 p{
  color: gray;
}

/* page2 */


.buyNow{
  width: 100%;
  height: 100vh;
  display: flex;
}

.buyNowDiv1{
  width: 40%;
  background-color: aliceblue;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
}

.buyNowDiv2{
  width: 60%;
  padding: 40px;
}


.buyNowDiv1 img{
  width: 100%;
}



.bookButtonCont{
  background-color: black;
  height: 9vh; 
  margin-bottom: 50px;
  border-radius: 30px;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  margin-top: 30px;
}



.bookButtonCont p{
  font-size: 13px;
  margin: 0;
  padding: 0;
}



.bookButtonCont h6{

  margin: 0;
  padding: 0;
}


.bookButtonCont button{
  background-color: royalblue;
  padding: 7px 25px;
  font-size: 13px;
  color: white !important;
  border: 1px royalblue solid;
  border-radius: 20px;

}


.successPage{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}


.checkCircle{
  font-size: 150px;
  color: green;
  margin-bottom: 50px;
}

.myopacity{
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  position: absolute;
  top: 0;
  z-index: 1;
}

.mainPageCont{
  position: absolute;
  top: 0;
  z-index: 11;
  color: white;
  padding: 20px;
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.mainPageCont h2{
  font-weight: bolder;
}

.btnAbout{
  background-color: white;
  padding: 10px 30px;
  border: 1px white solid;
}

.btnContact{
  background-color: transparent;
  padding: 10px 30px;
  border: 1px white solid;
  color: white;
}

.menuPage{
  display: flex;
  align-items: center;
  height: 10vh;
  justify-content: space-between;
}

.menuPage li{
  display: inline;
  margin-left: 10px;
  margin-right: 10px;
}

.contactUs{
  background-color: red;
  color: white;
  padding: 10px 30px;
  border-radius: 20px;
  cursor: pointer;
}

.contactUsNew{
  display: none;
  background-color: red;
  color: white;
  padding: 7px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 12px;
  border: none;
}


.newSpan{
  font-size: 14px;
}


.logo{
  height: 10vh;
  cursor: pointer;
}

.contactMain{
  width: 100%;
  display: flex;
  margin-top: 20px;
  height: 85vh;
}

.contactLeft iframe{
  width: 100%;
  height: 100%;
}
.contactLeft{
  width: 60%;
}

.contactRight{
  width: 40%;
  padding: 20px;
}

@media screen and (max-width:768px) {
  
  .mainPage{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    border-radius: 20px;
  }

  .heroDiv{
    width: 100%;
    height: 60vh; 
    position: relative;
    overflow: hidden;
    margin-bottom: 40px;
   }
   
   .prodDiv1{
    width: 100%;
    height: 400px;
    border-radius: 7px;
    padding: 0px;
    float: left;
    margin-right: 0px;
  }

  .logo{
    height: 7vh; 
  }

  .contactMain{
    width: 100%;
    display: flex;
    margin-top: 20px;
    height: 85vh;
    flex-direction: column;
  }
  
  .contactLeft iframe{
    width: 100%;
    height: 100%;
  }
  .contactLeft{
    width: 100%;
    height: 60vh;
  }
  
  .contactRight{
    width: 100%;
    padding: 20px;
  }
  /*  */

  .buyNow{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .buyNowDiv1{
    width: 100%;
    height: 40vh;
    background-color: aliceblue;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 50px;
    padding-right: 50px;
  }
  
  .buyNowDiv2{
    width: 100%;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }


  .successPage{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    padding: 20px;
    text-align: center;
  }

  .mainPageCont{
    position: absolute;
    top: 0;
    z-index: 11;
    color: white;
    padding: 10px;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
  }

  .mainPageCont p{
    font-size: 12px;
  }

  .btnAbout{
    background-color: white;
    padding: 7px 20px;
    border: 1px white solid;
    font-size: 12px;
  }
  
  .btnContact{
    background-color: transparent;
    padding: 7px 20px;
    border: 1px white solid;
    color: white;
    font-size: 12px;
  }
  .contactUsNew{
    display: block;
    text-decoration: none;
  }

  .theMenuPage{
    display: none;
  }
  
   

}